import { HandleRequestData } from '../../common/interfaces/handleRequest';
import { ValidationType } from '../../common/utils/formValidation';
import scheduleManagementApi from '../api/scheduleManagementApi';
import { InstructorAvailabilityData } from '../components/Activity/Common/Common';
import { DEFAULT_ACTIVITY_SOURCE_FIELDS } from '../constants';
import {
    ActivityData,
    CreateActivityData,
    Customer,
    DeliveryInstructor,
    InstructorForActivityValidationProps,
    Invoice,
} from '../interfaces/activity';
import { ActivityListResponseData } from '../interfaces/activityListResponse';
import { CreateActivityResponseData } from '../interfaces/createActivityResponse';
import { ScheduleManagementAPIQueryParams } from '../interfaces/queryParams';
import { CreateLMSClassResponse } from '../interfaces/lmsServiceResponse';
import lmsManagementApi from '../api/lmsManagementApi';
import { ApiError } from '../../common/classes/ApiError';
import { SelectedActivityResponseData } from '../interfaces/selectedActivityResponse';

export const validateInstructorsForActivity = async ({
    id,
    instructors,
    delivery_sessions,
    delivery_timezone,
    delivery_language,
    delivery_country,
    course_name,
    auto_assign_instructor,
}: InstructorForActivityValidationProps) => {
    const instructor_pks = instructors.flatMap(({ pk }) => (pk ? [pk] : []));
    const params = {
        id,
        instructor_pks,
        delivery_sessions,
        delivery_timezone,
        delivery_language,
        delivery_country,
        course_name,
        auto_assign_instructor,
    } as ScheduleManagementAPIQueryParams.GetValidatedActivityInstructors;
    if (instructor_pks.length === 0) {
        return [];
    }
    const {
        result: { instructors: validatedInstructorPks },
    } = await scheduleManagementApi.validateActivityInstructors(params);
    return Object.values(validatedInstructorPks).map(
        ({
            availability,
            back_to_back_activities,
            booked_activities,
            pk,
            warning_messages,
            error_messages,
            full_name,
            delivery_languages,
            delivery_countries,
            courses,
        }) => ({
            booked_activities,
            back_to_back_activities,
            pk,
            warning_messages,
            availability,
            error_messages,
            full_name,
            delivery_languages,
            delivery_countries,
            courses,
        }),
    );
};

export interface InstructorActivityQualificationResponse {
    instructor: InstructorAvailabilityData | null;
    missingQualifications: Array<string>;
}

export const validateInstructorActivityQualifications = (
    instructors: Array<InstructorAvailabilityData>,
) => {
    for (let instructor of instructors) {
        const { warning_messages } = instructor;
        let missingInstructorQualifications: Array<string> = [];

        if (warning_messages.length > 0) {
            missingInstructorQualifications = warning_messages;
        }

        if (missingInstructorQualifications.length > 0) {
            return {
                instructor,
                missingQualifications: missingInstructorQualifications,
            } as InstructorActivityQualificationResponse;
        }
    }

    return {
        instructor: null,
        missingQualifications: [],
    } as InstructorActivityQualificationResponse;
};

export const handleCheckInstructorActivityValidation = (
    validationResponse: any,
    requiresInstructorActivityValidation: boolean,
    formValues: ActivityData,
    validatedInstructors: Array<string>,
): Array<any> => {
    let confirmations = [];

    for (let instructor of validationResponse) {
        if (validatedInstructors.includes(instructor.pk)) {
            continue;
        }

        const { back_to_back_activities, warning_messages } = instructor;

        // check for confirm / reassign if delivery sessions have changed
        if (requiresInstructorActivityValidation) {
            // check for adjacent activities
            if (back_to_back_activities.length > 0) {
                confirmations.push({
                    type: 'adjacent',
                    instructor,
                });
            }
        }

        // check for languages
        const missingQualifications = warning_messages.filter((m: string) => {
            return ['wrong_language', 'wrong_country'].includes(m);
        });

        if (missingQualifications.length > 0) {
            confirmations.push({
                type: 'qualification',
                instructor,
                missingQualifications,
            });
        }

        // reschedules?
        if (instructor.availability === 'booked') {
            confirmations.push({
                type: 'reassign',
                instructor,
            });
        }
    }

    return confirmations;
};

export const doesActivityHavePrimaryInstructor = (
    instructorAttributeEditorItems: Array<DeliveryInstructor>,
): boolean => {
    if (!instructorAttributeEditorItems) {
        return false;
    }
    for (let instructor of instructorAttributeEditorItems) {
        const { role: instructorRole } = instructor;

        if (instructorRole === 'Primary') {
            return true;
        }
    }
    return false;
};

export const doesActivityHaveOnlyOnePrimaryInstructor = (
    instructorAttributeEditorItems: Array<DeliveryInstructor>,
): string | null => {
    let primaryCount: number = 0;

    for (let instructor of instructorAttributeEditorItems) {
        const { role: instructorRole, pk: instructorPk } = instructor;

        if (instructorRole === 'Primary') {
            primaryCount++;
            if (primaryCount > 1) {
                return instructorPk;
            }
        }
    }

    return null;
};

export const doesActivityHaveSameDeliveryDetails = (
    initialFormState: ActivityData,
    formValues: ActivityData,
): boolean | null => {
    const isSameDeliverySession =
        initialFormState.delivery_sessions === formValues.delivery_sessions;
    const isSameDeliveryLanguage =
        initialFormState.delivery_language === formValues.delivery_language;
    const isSameDeliveryTimezone =
        initialFormState.delivery_timezone === formValues.delivery_timezone;
    return (
        isSameDeliverySession &&
        isSameDeliveryLanguage &&
        isSameDeliveryTimezone
    );
};

export const doesActivityHaveDifferentPrimaryInstructor = (
    initialFormState: ActivityData,
    instructorAttributeEditorItems: Array<DeliveryInstructor>,
): boolean | null => {
    if (initialFormState.instructors) {
        if (!instructorAttributeEditorItems) {
            return true;
        } else {
            const initialPrimaryInstructor = initialFormState.instructors.find(
                (deliveryInstructor) => deliveryInstructor.role === 'Primary',
            );
            const changedPrimaryInstructor =
                instructorAttributeEditorItems.find(
                    (deliveryInstructor) =>
                        deliveryInstructor.role === 'Primary',
                );
            if (initialPrimaryInstructor && changedPrimaryInstructor) {
                return (
                    initialPrimaryInstructor.pk !== changedPrimaryInstructor.pk
                );
            }
            return true;
        }
    }
};

export const getInstructorAvailabilityStatus = (
    instructorValidationResponse: InstructorAvailabilityData[],
    availability: Array<string>,
) =>
    instructorValidationResponse.filter((instructor) =>
        availability.includes(instructor.availability),
    ).length === 0;

export const getInstructorAvailabilityStatusPerInstructor = (
    validationInstructor: DeliveryInstructor,
    instructorValidationResponse: InstructorAvailabilityData[],
    availability: Array<string>,
) =>
    instructorValidationResponse.filter(
        (instructor) =>
            instructor.pk === validationInstructor.pk &&
            availability.includes(instructor.availability),
    ).length === 0;

export const saveInvoiceValidationConfig: {
    [key in ValidationType]?: Array<keyof Invoice>;
} = {
    required: ['customer_name'],
};

export const saveInvoiceValidationNonDraftConfig = (
    is_reseller: boolean,
): {
    [key in ValidationType]?: Array<keyof Invoice>;
} => {
    let required_params: Array<keyof Invoice> = [
        'customer_name',
        'number_of_participants',
        'currency',
        'bill_to_aws_account_id',
        'payment_terms',
        'bill_to_first_name',
        'bill_to_last_name',
        'bill_to_address_1',
        'bill_to_city',
        'bill_to_state',
        'bill_to_country',
        'bill_to_postal_code',
        'bill_to_email',
    ];
    if (is_reseller) {
        // for resellers, we need to enter line items
        required_params.push('line_items');
    } else {
        // for non-reseller, we need this end customer, but not required for resellers
        required_params.push('customer_aws_account_id');
    }
    return {
        required: required_params,
    };
};

export const saveCustomerValidationConfig: {
    [key in ValidationType]?: Array<keyof Customer>;
} = {
    required: ['customer_name'],
};

export const getActivities = async (
    filters: ScheduleManagementAPIQueryParams.GetActivities = {},
) => {
    try {
        const {
            result: { activities = [], total_activities = 0 },
        }: HandleRequestData<ActivityListResponseData> =
            await scheduleManagementApi.getActivities({
                from: 0,
                size: 10,
                activity_status: ['Hold', 'Tentative', 'Active'],
                source_fields: DEFAULT_ACTIVITY_SOURCE_FIELDS,
                ...filters,
            } as ScheduleManagementAPIQueryParams.GetActivities);

        return { activities, total_activities };
    } catch (error: any) {
        throw error;
    }
};

export const getActivityGroupData = async (name: string) => {
    const activityGroup = (
        await scheduleManagementApi.getActivityGroupByName({ name })
    ).result;
    const { activities: childActivities } = await getActivities({
        activity_group_name: [name],
    });
    return { activityGroup, childActivities };
};

export const mergeActivityIntoActivity: any = async (
    toBeMergedPk: string,
    activityPk: string,
) => {
    try {
        const {
            result: { activity_id },
        }: HandleRequestData<CreateActivityResponseData> =
            await scheduleManagementApi.mergeActivity(activityPk, toBeMergedPk);

        return { activity_id };
    } catch (error: any) {
        throw error;
    }
};

export const createKikuClassForActivity: any = async (
    activityPk: string,
    classSize: number,
    lmsId?: string,
) => {
    try {
        const createResponse: HandleRequestData<CreateLMSClassResponse> =
            await lmsManagementApi.createLMSClass(
                activityPk,
                'kiku',
                classSize,
                lmsId,
            );

        return createResponse.result;
    } catch (error: any) {
        return (error as ApiError)?.response?.errors
            ? (error as ApiError)?.response
            : {
                  lms_id: null,
                  class_size: null,
                  warnings: [],
                  errors: [
                      'System could not create a Kiku class. Please try again.',
                  ],
              };
    }
};

export const generateActivityName = (
    activity: ActivityData | Omit<CreateActivityData, 'delivery_sessions'>,
    countryCode: string | undefined,
    activityCourseShortName: string | undefined,
) => {
    const displayCountryCode = countryCode ?? activity.delivery_country;
    const course =
        activityCourseShortName ?? activity.course_name.substring(0, 10);

    const activityName = [
        displayCountryCode,
        activity.activity_modality,
        activity.activity_audience,
        course,
        activity.delivery_language,
    ]
        .filter((v) => {
            return !!v;
        })
        .join('-');

    return activityName.replace(/\s+/g, '');
};

export const getActivityById = async (activityPk: string) => {
    try {
        const activityResponse: HandleRequestData<SelectedActivityResponseData> =
            await scheduleManagementApi.getActivityById({ id: activityPk });

        return activityResponse.result ?? null;
    } catch (e) {
        throw new Error('Exception while handling get Activity Request');
    }
};
