import dayjs from "dayjs";
import { Fragment, useMemo } from "react";
import {
    FormField,
    Popover,
    ColumnLayout,
    Button,
    Box,
    Select,
    SpaceBetween,
    TokenGroup,
    TextContent,
} from "@amzn/awsui-components-react-v3";
import { useDispatch } from "react-redux";
import { useDeliverySessionTypes, useActivityGroup } from "../hooks";
import { valuesToOptions, valueToOption } from "../../../../../common/utils/cloudscapeHelpers";
import { DAYS_OF_THE_WEEK } from "../../../../constants/datetime";
import { activityGroupSlice } from "../../../../store/slices/activityGroupSlice";
import { DeliverySession } from "../../../../interfaces/activity";

interface SessionDetailsProps {
    sessions: DeliverySession[],
    deliveryName: string,
    optionNumber: number,
    optionStartTime: string,
    optionStartTimeMeridiem: string,
    optionEndTime: string,
    optionEndTimeMeridiem: string,
    defaultSessionStartTime: number,
    defaultSessionEndTime: number,
    isReadOnlyMode: boolean,
    selectedWeekStartDay: dayjs.Dayjs,
    selectedWeekEndDay: dayjs.Dayjs,
}

const SessionDetails = (props: SessionDetailsProps) => {    
    const {
        sessions,
        deliveryName,
        optionNumber,
        optionStartTime,
        optionStartTimeMeridiem,
        optionEndTime,
        optionEndTimeMeridiem,
        defaultSessionStartTime,
        defaultSessionEndTime,
        isReadOnlyMode,
    } = props;
    
    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const deliverySessionTypes = useDeliverySessionTypes();
    const [ optionStartTimeHours, optionStartTimeMinutes ] = optionStartTime.split(":").map((val) => parseInt(val));
    const [ optionEndTimeHours, optionEndTimeMinutes ] = optionEndTime.split(":").map((val) => parseInt(val));
    const startTimeMeridiemOffsetHours = optionStartTimeMeridiem === "PM" ? 12 : 0;
    const endTimeMeridiemOffsetHours = optionEndTimeMeridiem === "PM" ? 12 : 0;
    const activityGroupStartDay = dayjs.tz(dayjs.unix(activityGroup.start_timestamp), activityGroup.selected_timezone);
    const activityGroupEndDay = dayjs.tz(dayjs.unix(activityGroup.end_timestamp), activityGroup.selected_timezone);
    const instructorOptions = activityGroup.instructor_pool.map((instructor) => ({
        value: JSON.stringify(instructor),
        label: `${instructor.name} (${instructor.email})`
    }));
    const addDeliverySession = () => dispatch(activityGroupSlice.actions.addDeliverySession({
        deliveryName,
        optionNumber,
        deliverySession: {
            start_timestamp:  defaultSessionStartTime,
            end_timestamp: defaultSessionEndTime,
            delivery_session_type: deliverySessionTypes[0],
            instructors: []
        }
    }));
    const setDeliverySession = (deliverySession: DeliverySession) => dispatch(activityGroupSlice.actions.setDeliverySession({
        deliveryName,
        optionNumber,
        deliverySession,
    }));
    const removeDeliverySession = (id: string) => dispatch(activityGroupSlice.actions.removeDeliverySession({
        deliveryName,
        optionNumber,
        id,
    }));

    const weekDayOptions = useMemo(() => {
        if (props.selectedWeekStartDay.isBefore(activityGroupStartDay)) {
            return valuesToOptions(DAYS_OF_THE_WEEK.slice(
                activityGroupStartDay.day(),
                DAYS_OF_THE_WEEK.length + 1,
            ));
        } else if (activityGroupEndDay.isBefore(props.selectedWeekEndDay)) {
            return valuesToOptions(DAYS_OF_THE_WEEK.slice(
                0,
                activityGroupEndDay.day() + 1,
            ));
        }

        return valuesToOptions(DAYS_OF_THE_WEEK);
    }, [activityGroupStartDay, activityGroupEndDay, props.selectedWeekStartDay, props.selectedWeekEndDay]);

    const sortedSessions = [...sessions].sort((session, next) => session.start_timestamp - next.start_timestamp);
    
    return <SpaceBetween size="l">
        <ColumnLayout columns={5} minColumnWidth={1}>
            {sortedSessions.map((session, index) => {
                const isHeaderRow = index === 0;
                const dayOfSession = session.start_timestamp
                    ? dayjs.tz(dayjs.unix(session.start_timestamp), activityGroup.selected_timezone)
                    : activityGroupStartDay;
                const sessionInstructorOptions = session.instructors?.map((instructor) => ({
                    label: `${instructor.name} (${instructor.email})`,
                    value: JSON.stringify(instructor) }
                )) || [];
                
                return <Fragment key={session.id}>
                    <FormField label={isHeaderRow && 'Session Type'} >
                        <Select
                            options={valuesToOptions(deliverySessionTypes)}
                            selectedOption={valueToOption(session.delivery_session_type || deliverySessionTypes[0])}
                            disabled={isReadOnlyMode}
                            onChange={({ detail }) => setDeliverySession({
                                ...session,
                                delivery_session_type: detail.selectedOption.value
                            })}
                            data-testid={`session-type-select-${deliveryName}-${optionNumber}-${index}`}
                        ></Select>
                    </FormField>

                    <FormField label={isHeaderRow && "Session Day"}>
                        <Select
                            options={weekDayOptions}
                            selectedOption={valueToOption(DAYS_OF_THE_WEEK[dayOfSession.day()])}
                            disabled={isReadOnlyMode}
                            onChange={({ detail }) => {
                                const dayOfWeekIndex = DAYS_OF_THE_WEEK.indexOf(detail.selectedOption.value);
                                const sessionDay = dayjs.tz(dayjs.unix(session.start_timestamp), activityGroup.selected_timezone);
                                const startOfSessionWeek = sessionDay.startOf("week");
                                const newSessionStart = startOfSessionWeek
                                    .add(dayOfWeekIndex, "day")
                                    .hour(optionStartTimeHours + startTimeMeridiemOffsetHours)
                                    .minute(optionStartTimeMinutes)
                                    .second(0);

                                const newSessionEnd = startOfSessionWeek
                                    .add(dayOfWeekIndex, "day")
                                    .hour(optionEndTimeHours + endTimeMeridiemOffsetHours)
                                    .minute(optionEndTimeMinutes)
                                    .second(0);

                                setDeliverySession({
                                    ...session,
                                    start_timestamp: newSessionStart.unix(),
                                    end_timestamp: newSessionEnd.unix()
                                });
                            }}
                            data-testid={`session-day-select-${deliveryName}-${optionNumber}-${index}`}
                        ></Select>
                    </FormField>

                    <FormField stretch={true} label={isHeaderRow && 'Instructors'}>
                        <SpaceBetween direction="horizontal" size="s">
                            <Popover
                                header="Add Instructor"
                                content={<Select
                                    options={instructorOptions}
                                    selectedOption={null}
                                    placeholder="Select an Instructor"
                                    onChange={({ detail }) => {
                                        const instructor = JSON.parse(detail.selectedOption.value);
                                        const existingInstructors = [...(session.instructors || [])].filter((i) => i.pk !== instructor.pk);
                                        setDeliverySession({
                                            ...session,
                                            instructors: [...existingInstructors, instructor]
                                        });
                                    }}
                                    empty='No Instructors in pool'
                                    data-testid={`add-instructor-select-${deliveryName}-${optionNumber}-${index}`}
                                ></Select>}
                                data-testid={`add-instructor-popover-${deliveryName}-${optionNumber}-${index}`}
                            >
                                <Button
                                    iconName="add-plus"
                                    disabled={isReadOnlyMode}
                                    data-testid={`add-instructor-button-${deliveryName}-${optionNumber}-${index}`}
                                ></Button>
                            </Popover>

                            <Box float="right">
                                <TokenGroup
                                    disableOuterPadding
                                    items={sessionInstructorOptions}
                                    readOnly={isReadOnlyMode}
                                    onDismiss={(e) => {
                                        const instructor = JSON.parse(sessionInstructorOptions[e.detail.itemIndex].value);
                                        setDeliverySession({
                                            ...session,
                                            instructors: session.instructors?.filter((i) => i.pk !== instructor.pk)
                                        });
                                    }}
                                    data-testid={`instructor-tokengroup-${deliveryName}-${optionNumber}-${index}`}
                                ></TokenGroup>
                            </Box>
                        </SpaceBetween>
                    </FormField>

                    {/* TODO: Hook up to state when back end supports this value */}
                    <FormField label={isHeaderRow && 'Meeting Link Type'}>
                        <Select
                            selectedOption={{
                                value: "PVILT",
                                label: "PVILT"
                            }}
                            options={[
                                {
                                value: "PVILT",
                                label: "PVILT"
                            }, {
                                value: "WebEx",
                                label: "WebEx"
                            }]}
                            disabled={isReadOnlyMode}
                            data-testid={`meeting-type-select-${deliveryName}-${optionNumber}-${index}`}
                        ></Select>
                    </FormField>

                    <Box>
                        <Box display="inline-block" float="right" padding={ {left: "s"}}>
                            {isHeaderRow && <TextContent>Delete</TextContent>}
                            <Button
                                iconName="remove"
                                disabled={isReadOnlyMode}
                                onClick={() => removeDeliverySession(session.id)}
                                data-testid={`delete-session-button-${deliveryName}-${optionNumber}-${index}`}
                            ></Button>
                        </Box>
                    </Box>
                </Fragment>
            })}
        </ColumnLayout>

        {!isReadOnlyMode && <Button
            onClick={() => addDeliverySession()}
            data-testid={`add-session-button-${deliveryName}-${optionNumber}`}
        >Add Session</Button>}
    </SpaceBetween>
};

export default SessionDetails;