import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import businessDataApi from '../../../../common/api/businessDataApi';
import { GlobalState } from '../../../../main/store';
import { AciDeliverySessionType } from '../../../constants/aci';
import { getCatalogCoursesByMultipleGroupIds } from '../../../../common/api/helpers';
import { LocationItemData } from '../../../../common/interfaces/businessDataItem/locationItem';

export const useActivityGroup = () =>
    useSelector((state: GlobalState) => state.activityGroup);

type CourseOption = {
    courseName: string;
    catalogVersionedId: string;
    catalogId: string;
};

type ActivityGroupFormOptions = {
    activityStatuses: string[];
    courseOptions: CourseOption[];
    programs: string[];
    timezones: string[];
};

export const useActivityDetailsFormOptions = (): [
    ActivityGroupFormOptions,
    boolean,
    boolean,
] => {
    const [formOptionsLoading, setFormOptionsLoading] = useState(false);
    const [coursesLoading, setCoursesLoading] = useState(false);

    const [formOptions, setFormOptions] = useState<ActivityGroupFormOptions>({
        activityStatuses: [],
        courseOptions: [],
        programs: [],
        timezones: [],
    });

    const activityGroup = useActivityGroup();

    useEffect(() => {
        setFormOptionsLoading(true);

        const courseOptions: CourseOption[] = [];

        const getActivityStatuses = () =>
            businessDataApi
                .getActivityStatuses({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_STATUS.map(
                        ({ activity_status }) => activity_status,
                    ),
                );
        const getPrograms = () =>
            businessDataApi
                .getProgramTypes({ active: true })
                .then(({ result }) =>
                    result.PROGRAM_TYPE.filter(
                        (program) =>
                            Array.isArray(program.catalog_group_ids) &&
                            program.catalog_group_ids.length,
                    ),
                );
        const getTimezones = () =>
            businessDataApi
                .getLocations()
                .then(({ result }) =>
                    result.CITY.map(({ city_timezone }) => city_timezone),
                );

        Promise.all([
            getActivityStatuses(),
            getPrograms(),
            getTimezones(),
        ]).then(async ([activityStatuses, programs, timezones]) => {
            if (activityGroup.program_name) {
                const selectedProgram = programs.find(
                    (program) =>
                        program.program_type === activityGroup.program_name,
                );
                if (selectedProgram.catalog_group_ids?.length) {
                    setCoursesLoading(true);

                    const catalogCourses =
                        await getCatalogCoursesByMultipleGroupIds(
                            selectedProgram.catalog_group_ids,
                        );
                    catalogCourses.forEach((course) =>
                        courseOptions.push({
                            courseName: course.name,
                            catalogVersionedId: course.versionedId,
                            catalogId: course.id,
                        }),
                    );

                    setCoursesLoading(false);
                }
            }

            setFormOptions({
                activityStatuses,
                courseOptions,
                programs: programs.map(({ program_type }) => program_type),
                timezones: timezones
                    .reduce((acc, timezone) => {
                        return [
                            ...acc.filter((tz) => tz !== timezone),
                            timezone,
                        ];
                    }, [])
                    .sort(),
            });

            setFormOptionsLoading(false);
        });
    }, [activityGroup.program_name]);

    return [formOptions, formOptionsLoading, coursesLoading];
};

export const useDeliveryDetailsFormOptions = () => {
    type FormOptions = {
        activityTypes: string[];
        activityModalities: string[];
        activityAudiences: string[];
        locations: LocationItemData[];
    };

    const [formOptions, setFormOptions] = useState<FormOptions>({
        activityTypes: [],
        activityModalities: [],
        activityAudiences: [],
        locations: [],
    });

    useEffect(() => {
        const getActivityTypes = () =>
            businessDataApi
                .getActivityTypes({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_TYPE.map(
                        ({ activity_type }) => activity_type,
                    ),
                );
        const getActivityModalities = () =>
            businessDataApi
                .getActivityModalities({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_MODALITY.map(
                        ({ activity_modality }) => activity_modality,
                    ),
                );
        const getActivityAudiences = () =>
            businessDataApi
                .getActivityAudiences({ active: true })
                .then(({ result }) =>
                    result.ACTIVITY_AUDIENCE.map(
                        ({ activity_audience }) => activity_audience,
                    ),
                );
        const getLocations = () =>
            businessDataApi
                .getLocations({ active: true })
                .then(({ result }) => result.CITY);

        Promise.all([
            getActivityTypes(),
            getActivityModalities(),
            getActivityAudiences(),
            getLocations(),
        ]).then(
            ([
                activityTypes,
                activityModalities,
                activityAudiences,
                locations,
            ]) => {
                setFormOptions({
                    activityTypes,
                    activityModalities,
                    activityAudiences,
                    locations,
                });
            },
        );
    }, []);

    return formOptions;
};

// TODO: Store this in the Program model instead of hard coding
export const useDeliverySessionTypes = () =>
    Object.values(AciDeliverySessionType);
