export const ACTIVITY_PATH = {
    BASE: '/activities',
    CREATE: '/activities/create',
    CLONE: '/activities/:id/clone',
    INSTRUCTORS_LIST: '/activities/instructors',
    DETAIL: '/activities/:id',
    LIST: '/activities',
    EDIT_DELIVERY_DETAILS: '/activities/:id/edit-delivery-details',
    EDIT_BASE_DETAILS: '/activities/:id/edit-activity-details',
    EDIT_OPERATIONS_AND_REGISTRATION_DETAILS:
        '/activities/:id/edit-operations-and-registration-details',
    CREATE_INVOICE: '/activities/:id/create-invoice',
    EDIT_INVOICE: '/activities/:id/edit-invoice/:invoiceId',
    CREATE_CUSTOMER: '/activities/:id/create-customer',
    EDIT_CUSTOMER: '/activities/:id/edit-customer/:customerId',
    CREATE_APN_CUSTOMER: '/activities/:id/create-apn-customer',
    EDIT_APN_CUSTOMER: '/activities/:id/edit-apn-customer/:customerId',
    CLIPPER_EXPORT: '/activities/clipper-export',
    SFDC_QUEUE: '/activities/sfdc-queue',
    SFSDC_OPPORTUNITY: '/activities/opportunity/:id',
    ACTIVITY_IMPORT: '/activities/activities-import',
    FREELANCER_IMPORT: '/activities/freelancer-import',
    BLOCKED_TIMES_IMPORT: '/activities/blocked-times-import',
    GROUP_BASE: '/activities/group',
    CREATE_GROUP: '/activities/group/create',
    EDIT_GROUP: '/activities/group/:name/edit',
    GROUP_VIEW: '/activities/group/:name',
} as const;
