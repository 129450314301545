import { FCResourceViewTypes } from '../enums/fcResourceVIewTypes';

export const CALENDAR_EVENT_COLOR = '#0073BB';
export const UNAVAILABLE_EVENT_COLOR = '#F2F3F3';
export const INITIAL_CALENDAR_VIEW_TYPE = FCResourceViewTypes.Week;

export const DEFAULT_ACTIVITY_STATUS = 'Hold';
export const DEFAULT_LANGUAGE = 'English';
export const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_PROVIDER = 'AWS';

export const TOF_STATUS_OPTIONS = [
    'Not Started',
    'With Customer',
    'Signature Received',
    'Expired',
];

export const DEFAULT_ACTIVITY_SOURCE_FIELDS = [
    'pk',
    'activity_name',
    'activity_status',
    'activity_audience',
    'activity_modality',
    'activity_group_name',
    'program',
    'room',
    'course_name',
    'course_catalog_item_id',
    'activity_type',
    'provider',
    'operation_owners_email',
    'delivery_sessions',
    'delivery_country',
    'delivery_language',
    'instructors',
    'delivery_timezone',
    'delivery_address_1',
    'delivery_address_2',
    'delivery_postal_code',
    'delivery_city',
    'delivery_state',
    'delivery_country',
    'lms_id',
    'lms_type',
    'customers',
    'class_size',
];
