import dayjs from "dayjs";
import { useState } from "react";
import {
    FormField,
    Button,
    Box,
    Container,
    Input,
    Select,
    SpaceBetween,
    Header,
    TimeInput,
} from "@amzn/awsui-components-react-v3";
import { useDispatch } from "react-redux";

import SessionDetails from "./SessionDetails";
import { meridiemFieldOptionsV3 } from "../../../../interfaces/meridiem";
import { useActivityGroup } from "../hooks";
import { activityGroupSlice } from "../../../../store/slices/activityGroupSlice";
import { valueToOption } from "../../../../../common/utils/cloudscapeHelpers";

interface DeliveryOptionProps {
    deliveryName: string;
    optionNumber: number;
    weekStartDate: string;
    isReadOnlyMode: boolean;
}

const DeliveryOption = ({ optionNumber, deliveryName, weekStartDate, isReadOnlyMode }: DeliveryOptionProps) => {
    const activityGroup = useActivityGroup();
    const dispatch = useDispatch();

    const weekStartDay = dayjs.tz(dayjs(weekStartDate, "YYYY/MM/DD"), activityGroup.selected_timezone);
    const weekEndDay = weekStartDay.add(1, "week");
    const defaultSessionStart = weekStartDay.unix() > activityGroup.start_timestamp
        ? weekStartDay.startOf("day").add(9, "hours")
        : dayjs.unix(activityGroup.start_timestamp);
    const defaultSessionEnd = activityGroup.end_timestamp < weekEndDay.unix()
        ? dayjs.unix(activityGroup.end_timestamp)
        : defaultSessionStart.add(1, "hours");
    const deliveryOptionActivity = activityGroup.child_activities.find(
        (activity) => activity.delivery_name === deliveryName && activity.option_number === optionNumber);

    const weekDeliverySessions = deliveryOptionActivity.delivery_sessions.filter((session) => {
        return session.start_timestamp >= weekStartDay.unix() && session.start_timestamp < weekEndDay.unix();
    });

    const lowestSessionStart = deliveryOptionActivity.delivery_sessions.reduce((acc, session) => {
        return session.start_timestamp < acc ? session.start_timestamp : acc;
    }, 0);
    const highestSessionEnd = deliveryOptionActivity.delivery_sessions.reduce((acc, session) => {
        return session.end_timestamp > acc ? session.end_timestamp : acc;
    }, 0);

    const optionStartDay = dayjs.unix(lowestSessionStart || defaultSessionStart.unix());
    const optionEndDay = dayjs.unix(highestSessionEnd || defaultSessionEnd.unix());

    const getTimeFromDay = (day: dayjs.Dayjs) => dayjs.tz(day, activityGroup.selected_timezone).format("hh:mm");
    const getMeridiemFromDay = (day: dayjs.Dayjs) => dayjs.tz(day, activityGroup.selected_timezone).format("A");

    const [optionStartTime, setOptionStartTime] = useState(getTimeFromDay(optionStartDay));
    const [optionEndTime, setOptionEndTime] = useState(getTimeFromDay(optionEndDay));
    const [startMeridiem, setStartMeridiem] = useState<string>(getMeridiemFromDay(optionStartDay));
    const [endMeridiem, setEndMeridiem] = useState<string>(getMeridiemFromDay(optionEndDay));
    const deliveryOptionName = `${deliveryName} (Option ${optionNumber})`;

    const removeOption = () => dispatch(activityGroupSlice.actions.removeDeliveryOption({
        deliveryName,
        optionNumber
    }));
    const cloneOption = () => dispatch(activityGroupSlice.actions.cloneDeliveryOption({
        deliveryName,
        optionNumberToClone: optionNumber,
    }));
    
    const handleOptionTimeUpdate = (time: string, timeField: "start_timestamp" | "end_timestamp", meridiem: string) => {
        const [ hours, minutes ] = time.split(":").map((val) => parseInt(val));

        const meridiemOffsetHours = meridiem === "PM" ? 12 : 0;

        deliveryOptionActivity.delivery_sessions.forEach((session) => {
            
            const sessionStartDay = session.start_timestamp ? dayjs.tz(dayjs.unix(session[timeField]), activityGroup.selected_timezone) : weekStartDay;
            const sessionDayStart = sessionStartDay.startOf("day");
            const updatedSessionTime = sessionDayStart
                .add(hours + meridiemOffsetHours, "hour")
                .add(minutes, "minute")
                .unix();

            if (updatedSessionTime) {
                dispatch(activityGroupSlice.actions.setDeliverySession({
                    deliveryName,
                    optionNumber,
                    deliverySession: {
                        ...session,
                        [timeField]: updatedSessionTime
                    }
                }));
            }
        });
    }

    const containerHeader = <Header
        variant='h3'
        actions={!isReadOnlyMode && <SpaceBetween size="s" direction="horizontal">
            <Button onClick={() => cloneOption()}>Clone</Button>

            {optionNumber > 1 && <Button onClick={() => removeOption()}>Delete</Button>}
        </SpaceBetween>}
    >{deliveryOptionName}</Header>;
    
    return <Container header={containerHeader}>
        <Box padding={{ bottom: "s" }}>
            <SpaceBetween size='s' direction='horizontal'>                
                <FormField label="Start Time">
                    <SpaceBetween size='xs' direction='horizontal'>
                        <TimeInput
                            value={optionStartTime}
                            format='hh:mm'
                            placeholder='hh:mm'
                            use24Hour={false}
                            disabled={isReadOnlyMode}
                            onChange={({ detail }) => {
                                handleOptionTimeUpdate(detail.value, "start_timestamp", startMeridiem);
                                setOptionStartTime(detail.value);
                            }}
                            data-testid={`option-${optionNumber}-start-time-input`}
                        ></TimeInput>
                        <Select
                            options={meridiemFieldOptionsV3}
                            selectedOption={valueToOption(startMeridiem)}
                            disabled={isReadOnlyMode}
                            onChange={({ detail }) => {
                                setStartMeridiem(detail.selectedOption.label);
                                handleOptionTimeUpdate(optionStartTime, "start_timestamp", detail.selectedOption.label);
                            }}
                            data-testid={`option-${optionNumber}-start-time-meridiem-select`}
                        ></Select>
                    </SpaceBetween>
                </FormField>

                <FormField label="End Time">
                    <SpaceBetween size='xs' direction='horizontal'>
                        <TimeInput
                            value={optionEndTime}
                            format='hh:mm'
                            placeholder='hh:mm'
                            use24Hour={false}
                            disabled={isReadOnlyMode}
                            onChange={({ detail }) => {
                                handleOptionTimeUpdate(detail.value, "end_timestamp", endMeridiem);
                                setOptionEndTime(detail.value);
                            }}
                            data-testid={`option-${optionNumber}-end-time-input`}
                        ></TimeInput>
                        <Select
                            options={meridiemFieldOptionsV3}
                            selectedOption={valueToOption(endMeridiem)}
                            disabled={isReadOnlyMode}
                            onChange={({ detail }) => {
                                setEndMeridiem(detail.selectedOption.label);
                                handleOptionTimeUpdate(optionEndTime, "end_timestamp", detail.selectedOption.label);
                            }}
                            data-testid={`option-${optionNumber}-end-time-meridiem-select`}
                        ></Select>
                    </SpaceBetween>
                </FormField>

                <FormField label='Class Size'>
                    <Input
                        value={`${deliveryOptionActivity.class_size || ''}`}
                        disabled={isReadOnlyMode}
                        onChange={({ detail }) => {                            
                            dispatch(activityGroupSlice.actions.setDeliveryOption({
                                deliveryName,
                                optionNumber,
                                class_size: detail.value ? parseInt(detail.value) : 0
                            }));
                        }}
                        placeholder="0"
                        inputMode="numeric"
                        data-testid={`option-${optionNumber}-class-size-input`}
                    ></Input>
                </FormField>
            </SpaceBetween>
        </Box>
        
        <SessionDetails
            sessions={weekDeliverySessions}
            deliveryName={deliveryName}
            optionNumber={optionNumber}
            optionStartTime={optionStartTime}
            optionStartTimeMeridiem={startMeridiem}
            optionEndTime={optionEndTime}
            optionEndTimeMeridiem={endMeridiem}
            defaultSessionStartTime={defaultSessionStart.unix()}
            defaultSessionEndTime={defaultSessionEnd.unix()}
            isReadOnlyMode={isReadOnlyMode}
            selectedWeekStartDay={weekStartDay}
            selectedWeekEndDay={weekEndDay}
        />
    </Container>
};

export default DeliveryOption;