import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

import { ACTIVITY_PATH } from '../../../../constants/path';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

import {
    Alert,
    AlertProps,
    Form,
    Button,
    Input,
    SpaceBetween,
    FormField,
    StatusIndicator,
} from '@amzn/awsui-components-react-v3';

import ActivityGroupDetails from './ActivityGroupDetails';
import DeliveryDetails from './DeliveryDetails';
import Delivery from './Delivery';
import { useActivityGroup } from '../hooks';
import { activityGroupSlice } from '../../../../store/slices/activityGroupSlice';
import scheduleManagementApi from '../../../../api/scheduleManagementApi';
import { ActivityGroupMode } from '../../../../interfaces/activityGroup';

export interface ActivityGroupFormProps {
    mode: ActivityGroupMode
}

type ActivityGroupAlertConfig = { type?: AlertProps.Type, visible: boolean, text?: ActivityGroupAlertText };

enum ActivityGroupAlertText {
    SAVE_SUCCESS = "Activity Group saved successfully",
    SAVE_FAILURE = "Failed to save Activity Group"
}

export const ActivityGroupForm = (props: ActivityGroupFormProps) => {    
    const dispatch = useDispatch();
    const activityGroup = useActivityGroup();
    const [ newDeliveryName, setNewDeliveryName ] = useState('');
    const [ shouldRedirectToEdit, setShouldRedirectToEdit ] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const [alertConfig, setAlertConfig] = useState<ActivityGroupAlertConfig>({ visible: false });
    const [isSaving, setIsSaving] = useState(false);
    const cityIsSetForEdits = () => !!(activityGroup.child_activities.length && activityGroup.child_activities[0]?.delivery_city);

    useEffect(() => {
        history.push(location);
    }, [location.pathname]);

    const isExistingGroupMode = ["EDIT", "VIEW"].includes(props.mode);
    const isReadOnlyMode = props.mode === 'VIEW';

    const handleSaveActivityGroup = async () => {     
        try {
            await scheduleManagementApi.putActivityGroup({
                name: activityGroup.name,
                active: true,
                status: activityGroup.status,
                program_name: activityGroup.program_name,
                catalog_item_id: activityGroup.catalog_item_id,
                catalog_item_versioned_id: activityGroup.catalog_item_versioned_id,
                start_timestamp: activityGroup.start_timestamp,
                end_timestamp: activityGroup.end_timestamp,
            });

            await Promise.all(activityGroup.child_activities.map((activity) => {
                const { pk, delivery_name, option_number, ...filteredActivity } = activity;
                
                if (pk) {
                    return scheduleManagementApi.updateActivityById({ id: pk, activity: filteredActivity });
                } else {
                    const genericDeliverySessions = filteredActivity.delivery_sessions.map((ds) => {
                        const { id, ...rest } = ds;
                        return rest;
                    })
                    return scheduleManagementApi.createActivity({
                        ...filteredActivity,
                        delivery_sessions: genericDeliverySessions
                    });
                }
            }));

            setAlertConfig({
                type: 'success',
                visible: true,
                text: ActivityGroupAlertText.SAVE_SUCCESS
            });

            setShouldRedirectToEdit(true);
        }
        catch {
            setAlertConfig({
                type: 'error',
                visible: true,
                text: ActivityGroupAlertText.SAVE_FAILURE
            });
        }
        finally {
            const alertElement =  document.querySelector('#activity-group-alert');

            if (alertElement) {
                alertElement.scrollIntoView({ behavior: 'smooth' });
            }

            setIsSaving(false);
        }
    }

    const createNewDeliveryField = <FormField label='Delivery Name'>
        <SpaceBetween direction='horizontal' size='s'>
            <Input
                type='text'
                value={newDeliveryName}
                onChange={(event) => setNewDeliveryName(event.detail.value)}
            ></Input>

            <Button
                onClick={() => {
                    dispatch(activityGroupSlice.actions.addDeliveryName(newDeliveryName));
                    setNewDeliveryName('');
                }}
                disabled={!newDeliveryName}
            >Add Delivery</Button>  
        </SpaceBetween>
    </FormField>

    const submitFormButton = <Button
        variant='primary'
        formAction='submit'
        disabled={isSaving || (props.mode === 'EDIT' && !cityIsSetForEdits())}
        disabledReason='Delivery Location must be set before saving'
        loading={isSaving}
        onClick={() => {
            setIsSaving(true);
            handleSaveActivityGroup();
        }}
    >{isSaving ? 'Saving...' : 'Save' }
    </Button>;

    const activityGroupAlert = <div id='activity-group-alert'>
        <Alert
            dismissible
            type={alertConfig.type}
            onDismiss={() => setAlertConfig({ ...alertConfig, visible: false })}
        >{alertConfig.text}</Alert>
    </div>

    const activityGroupForm = <Form actions={!isReadOnlyMode && submitFormButton}>        
        <SpaceBetween size='l' direction='vertical'>            
            {alertConfig.visible && activityGroupAlert}

            <ActivityGroupDetails mode={props.mode} redirectToEdit={() => setShouldRedirectToEdit(true)}></ActivityGroupDetails>

            {isExistingGroupMode && <>
                <DeliveryDetails isReadOnlyMode={isReadOnlyMode}></DeliveryDetails>
                { Object.keys(activityGroup.delivery_map).map((delivery) => <Delivery key={delivery} name={delivery} mode={props.mode}></Delivery>) } 
                { !isReadOnlyMode && createNewDeliveryField }
            </>}
        </SpaceBetween>

        {shouldRedirectToEdit && <Redirect
            to={`${ACTIVITY_PATH.EDIT_GROUP.replace(':name', encodeURIComponent(activityGroup.name))}`}
        ></Redirect>}
    </Form>;

    const loadingSpinner = <StatusIndicator type='loading'>Loading Activity Group...</StatusIndicator>;

    return activityGroup.is_loading ? loadingSpinner : activityGroupForm;
};

export default ActivityGroupForm;